.wrapper {
  width: 100vw;
  margin: 0 0 27px;
  position: relative;
  left: calc(-50vw + 50%);
}

.carousel {
  margin-bottom: 18px;
}

.tilesWrapper {
  -webkit-overflow-scrolling: touch;
  justify-content: space-between;
  width: 100vw;
  padding: 0 18px;
  display: flex;
  position: relative;
  left: calc(-50vw + 50%);
  overflow-x: scroll;
}

.tilesWrapper::-webkit-scrollbar {
  display: none;
}

.tile {
  color: #222;
  flex: 1;
  align-items: center;
  min-width: 35vw;
  margin-right: 18px;
  padding: 18px;
  text-decoration: none;
  display: flex;
  position: relative;
  border: 1px solid #ddd !important;
}

.tile:last-child {
  margin-right: 0;
}

@media (width <= 1024px) {
  .tile {
    flex-direction: column;
    justify-content: center;
  }
}

.tile:last-child {
  position: relative;
}

.tile:last-child:after {
  content: "";
  width: 18px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
}

.tileImage {
  width: 72px;
  height: 72px;
  margin-right: 18px;
  position: relative;
}

@media (width <= 1024px) {
  .tileImage {
    margin-right: 0;
  }
}

.tileTitle {
  letter-spacing: -.5px;
  text-align: center;
  word-break: break-word;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25;
  text-decoration: none;
}

@media (width <= 1024px) {
  .tileTitle {
    margin-top: 18px;
  }
}
