.eyeBrow {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 18px 0;
  padding: 0 9px;
  display: flex;
}

.text.captionAlignCenter {
  text-align: center;
}

.text.captionColor {
  color: #fff;
}

.staticWrapper {
  z-index: 10;
  width: 100%;
  height: 100%;
}

.cta.important:not(.ctaOutline):not(.ctaColor) {
  color: #fff;
  background-color: #222;
  border: 2px solid #222;
}

.cta.important:not(.ctaOutline):not(.ctaColor):after {
  border: thin solid #fff;
  box-shadow: 0 0 2px 2px #222;
}

.cta.important:not(.ctaOutline):not(.ctaColor):hover {
  color: #222;
  border-color: #fff;
}

.cta.important:not(.ctaOutline):not(.ctaColor):hover:not(.halfWidth) {
  background-color: #fff;
}

.cta.important:not(.ctaOutline).ctaColor {
  color: #222;
  background-color: #fff;
  border: 2px solid #fff;
}

.cta.important:not(.ctaOutline).ctaColor:after {
  border: thin solid #222;
  box-shadow: 0 0 2px 2px #fff;
}

.cta.important:not(.ctaOutline).ctaColor:hover {
  color: #fff;
  border-color: #222;
}

.cta.important:not(.ctaOutline).ctaColor:hover:not(.halfWidth) {
  background-color: #222;
}

.cta.important.ctaOutline:not(.ctaColor) {
  color: #222;
  background-color: #0000;
  border: 2px solid #222;
}

.cta.important.ctaOutline:not(.ctaColor):after {
  border: thin solid #fff;
  box-shadow: 0 0 2px 2px #222;
}

.cta.important.ctaOutline:not(.ctaColor):hover {
  color: #fff;
  border-color: #222;
}

.cta.important.ctaOutline:not(.ctaColor):hover:not(.halfWidth) {
  background-color: #222;
}

.cta.important.ctaOutline.ctaColor {
  color: #fff;
  background-color: #0000;
  border: 2px solid #fff;
}

.cta.important.ctaOutline.ctaColor:after {
  border: thin solid #222;
  box-shadow: 0 0 2px 2px #fff;
}

.cta.important.ctaOutline.ctaColor:hover {
  color: #222;
  border-color: #fff;
}

.cta.important.ctaOutline.ctaColor:hover:not(.halfWidth) {
  background-color: #fff;
}

.header {
  word-break: break-word;
  hyphens: auto;
  letter-spacing: -.5px;
  margin-top: 0;
  margin-bottom: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.header em {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.bodyText {
  letter-spacing: -.5px;
  margin-bottom: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.bodyText p {
  min-height: 18px;
}

.bodyText p:not(:first-child) {
  margin-top: 18px;
}
