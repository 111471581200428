.container {
  margin: 18px;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  padding-bottom: 9px;
}

.body {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}