.button {
  --noop-rule: 0;
}

.button:hover:not(:disabled) {
  color: #222;
  background: #fff;
  border-color: #222;
}

.heartLink {
  -webkit-tap-highlight-color: transparent;
  width: 33px;
  height: 33px;
}

.heartIcon {
  transition: fill .2s;
}
