/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  margin: -9px 0 36px;
  margin: 0 0 27px;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}

.carousel {
  margin-bottom: 18px;
}

.tilesWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  padding: 0 18px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.tilesWrapper::-webkit-scrollbar {
  display: none;
}

.tile {
  display: flex;
  padding: 18px;
  align-items: center;
  border: 1px solid #ddd !important;
  flex: 1;
  margin-right: 18px;
  position: relative;
  text-decoration: none;
  color: #222;
  min-width: 35vw;
}
.tile:last-child {
  margin-right: 0;
}
@media (max-width: 1024px) {
  .tile {
    flex-direction: column;
    justify-content: center;
  }
}
.tile:last-child {
  position: relative;
}
.tile:last-child:after {
  position: absolute;
  content: "";
  top: 0;
  left: 100%;
  width: 18px;
  height: 100%;
}

.tileImage {
  position: relative;
  margin-right: 18px;
  height: 72px;
  width: 72px;
}
@media (max-width: 1024px) {
  .tileImage {
    margin-right: 0;
  }
}

.tileTitle {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: center;
  text-decoration: none;
  word-break: break-word;
  line-height: 1.25;
}
@media (max-width: 1024px) {
  .tileTitle {
    margin-top: 18px;
  }
}