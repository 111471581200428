/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  padding: 18px 18px 27px;
}

.borderTop {
  border-top: 9px solid #f3f3f3;
}

.borderBottom {
  border-bottom: 9px solid #f3f3f3;
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0 0 18px;
}

.innerWrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.innerWrapper::-webkit-scrollbar {
  display: none;
}

.link.specify {
  margin-right: 9px;
}
.link.specify:last-child {
  margin-right: 0;
}

.linkContainer {
  display: flex;
  padding: 0 18px;
  width: fit-content;
}