.button {
  --noop-rule: 0;
}
.button:hover:not(:disabled) {
  background: #fff;
  border-color: #222;
  color: #222;
}

.heartLink {
  height: 33px;
  width: 33px;
  -webkit-tap-highlight-color: transparent;
}

.heartIcon {
  transition: fill 0.2s ease;
}