/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  margin-bottom: 36px;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}

.imgWrapper {
  overflow: hidden;
  height: 149px;
}

.image {
  width: 100%;
  height: auto;
}

.moduleWrapper {
  position: relative;
  background-color: #fff;
  padding: 0 18px;
}

.module {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-top: 18px;
}
.module:empty {
  padding-top: 0;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: #222;
  margin-bottom: 18px;
}

.subtitle {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: #222;
  margin-bottom: 18px;
}

.description {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
  margin-bottom: 18px;
}

.link {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 18px;
}

.arrow {
  height: 16px;
  width: 16px;
  margin-left: 5px;
  vertical-align: middle;
}