@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  align-items: center;
  margin: 18px 0 0;
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}
@supports (-webkit-line-clamp: 3) {
  .header {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
  .header {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

.subHeader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  margin: 9px 0 0 0;
}
@supports (-webkit-line-clamp: 3) {
  .subHeader {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
  .subHeader {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

.withFollowButton {
  margin-right: 9px;
}

.fewerResultsHeader {
  margin-top: 27px;
  border-top: 1px solid #f3f3f3;
  padding-top: 18px;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.shimmer {
  animation-name: shimmerLoadingBackground;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50%, #f3f3f3 51%, #ddd 100%);
  background-size: 400% 400%;
  backface-visibility: hidden;
  transform: translateZ(0);
}