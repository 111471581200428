.eyeBrow {
  margin-bottom: 18px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}

.text {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 18px 0;
  padding: 0 9px;
}
.text.captionAlignCenter {
  text-align: center;
}
.text.captionColor {
  color: #fff;
}

.staticWrapper {
  width: 100%;
  height: 100%;
  z-index: 10;
}

.cta.important:not(.ctaOutline):not(.ctaColor) {
  color: #fff;
  background-color: #222;
  border: solid 2px #222;
}
.cta.important:not(.ctaOutline):not(.ctaColor)::after {
  border: solid thin #fff;
  box-shadow: 0 0 2px 2px #222;
}
.cta.important:not(.ctaOutline):not(.ctaColor):hover {
  color: #222;
  border-color: #fff;
}
.cta.important:not(.ctaOutline):not(.ctaColor):hover:not(.halfWidth) {
  background-color: #fff;
}
.cta.important:not(.ctaOutline).ctaColor {
  color: #222;
  background-color: #fff;
  border: solid 2px #fff;
  border: solid 2px #fff;
}
.cta.important:not(.ctaOutline).ctaColor::after {
  border: solid thin #222;
  box-shadow: 0 0 2px 2px #fff;
}
.cta.important:not(.ctaOutline).ctaColor::after {
  border: solid thin #222;
  box-shadow: 0 0 2px 2px #fff;
}
.cta.important:not(.ctaOutline).ctaColor:hover {
  color: #fff;
  border-color: #222;
}
.cta.important:not(.ctaOutline).ctaColor:hover:not(.halfWidth) {
  background-color: #222;
}
.cta.important.ctaOutline:not(.ctaColor) {
  color: #222;
  background-color: #fff;
  border: solid 2px #fff;
  border: solid 2px #222;
  background-color: transparent;
}
.cta.important.ctaOutline:not(.ctaColor)::after {
  border: solid thin #222;
  box-shadow: 0 0 2px 2px #fff;
}
.cta.important.ctaOutline:not(.ctaColor)::after {
  border: solid thin #fff;
  box-shadow: 0 0 2px 2px #222;
}
.cta.important.ctaOutline:not(.ctaColor):hover {
  color: #fff;
  border-color: #222;
}
.cta.important.ctaOutline:not(.ctaColor):hover:not(.halfWidth) {
  background-color: #222;
}
.cta.important.ctaOutline.ctaColor {
  color: #fff;
  background-color: #222;
  border: solid 2px #222;
  border: solid 2px #fff;
  background-color: transparent;
}
.cta.important.ctaOutline.ctaColor::after {
  border: solid thin #fff;
  box-shadow: 0 0 2px 2px #222;
}
.cta.important.ctaOutline.ctaColor::after {
  border: solid thin #222;
  box-shadow: 0 0 2px 2px #fff;
}
.cta.important.ctaOutline.ctaColor:hover {
  color: #222;
  border-color: #fff;
}
.cta.important.ctaOutline.ctaColor:hover:not(.halfWidth) {
  background-color: #fff;
}

.header {
  margin-bottom: 18px;
  margin-top: 0;
  word-break: break-word;
  hyphens: auto;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}
.header em {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: 0;
}

.bodyText {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-bottom: 18px;
}
.bodyText p {
  min-height: 18px;
}
.bodyText p:not(:first-child) {
  margin-top: 18px;
}