.wrapper {
  width: 100vw;
  padding: 18px 18px 27px;
  position: relative;
  left: calc(-50vw + 50%);
}

.borderTop {
  border-top: 9px solid #f3f3f3;
}

.borderBottom {
  border-bottom: 9px solid #f3f3f3;
}

.header {
  letter-spacing: -.5px;
  margin: 0 0 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.innerWrapper {
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: scroll hidden;
}

.innerWrapper::-webkit-scrollbar {
  display: none;
}

.link.specify {
  margin-right: 9px;
}

.link.specify:last-child {
  margin-right: 0;
}

.linkContainer {
  width: fit-content;
  padding: 0 18px;
  display: flex;
}
